
import TYPES from '../types/webconferencia';

export const setAbaAtiva = (abaAtiva) => {
    return {
        type: TYPES.SET_ABA_ATIVA,
        payload: { abaAtiva }
    }
}

export const setDesabilitarAbaAoVivo = (desabilitarAbaAoVivo) => {
    return {
        type: TYPES.SET_DESABILITAR_ABA_AO_VIVO,
        payload: { desabilitarAbaAoVivo }
    }
}

export const setPesquisarEventosAndamento = (pesquisarEventosAndamento) => {
    return {
        type: TYPES.SET_PESQUISAR_EVENTOS_ANDAMENTO,
        payload: { pesquisarEventosAndamento }
    }
}

export const setPesquisarEventosAgendados = (pesquisarEventosAgendados) => {
    return {
        type: TYPES.SET_PESQUISAR_EVENTOS_AGENDADOS,
        payload: { pesquisarEventosAgendados }
    }
}

export const setParametrosPesquisa = (parametros) => {
    return {
        type: TYPES.SET_PARAMETROS_PESQUISA,
        payload: { parametros }
    };
}

export const limparParametrosPesquisa = () => {
    return {
        type: TYPES.LIMPAR_PARAMETROS_PESQUISA
    };
}