import React, { useState, useRef, lazy, Suspense, memo, useEffect, useContext } from 'react';
import {
    SttTabs,
    SttCircularProgress,
    SttTranslateHook
} from '@stt-componentes/core';
import { makeStyles } from "@material-ui/core/styles";
import { PERMISSOES, TIPO_PESQUISA_WEBS } from '../../../common/Constants';
import { connect } from 'react-redux';
import { temPermissaoRBAC } from '../../../security/acl';
import {
    setAbaAtiva as setAbaAtivaAction,
    limparParametrosPesquisa as limparParametrosPesquisaAction
} from '../../../reducers/actions/webconferencias';

const useStyles = makeStyles(theme => ({
    wrapper: {
        padding: theme.spacing(3)
    },
}));

const Webconferencias = lazy(() => import('../../../componentes/interno/pesquisa/webconferencia'));
const AoVivo = lazy(() => import('../../../componentes/interno/pesquisa/webconferencia/aoVivo'));

const AoVivoSuspense = memo((props) => {
    return (
        <Suspense fallback={<SttCircularProgress color="primary" />}>
            <AoVivo {...props} />
        </Suspense>
    )
});

const WebconferenciasSuspense = memo((props) => {
    return (
        <Suspense fallback={<SttCircularProgress color="primary" />}>
            <Webconferencias {...props} abaProximasWebs={false} />
        </Suspense>
    )
});

const PesquisaIndex = ({ user, abaAtiva, setAbaAtiva, desabilitarAbaAoVivo, limparParametrosPesquisa }) => {
    const classes = useStyles();
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const [abas, setAbas] = useState([]);
    const stateRef = useRef();

    stateRef.current = abas;

    useEffect(() => {
        if(desabilitarAbaAoVivo || temPermissaoRBAC(user, PERMISSOES.CADASTRAR_CURSO)) {
            setAbaAtiva('1');
        } else {
            setAbaAtiva('0');
        }
    }, [user]);

    useEffect(() => {
        setAbas([
            {
                titulo: strings.aoVivo,
                conteudo: AoVivoSuspense,
                key: 'AoVivoSuspense',
                disabled: desabilitarAbaAoVivo,
                permanente: true,
                tipoPesquisa: TIPO_PESQUISA_WEBS.AO_VIVO
            },
            {
                titulo: strings.webconferencias,
                conteudo: WebconferenciasSuspense,
                key: 'WebconferenciasSuspense',
                permanente: true,
                tipoPesquisa: TIPO_PESQUISA_WEBS.WEBS_GRAVADAS
            }
        ]);
    }, []);

    useEffect(() => {
        if (abas.length) {
            const novasAbas = [...abas];
            novasAbas[0].disabled = desabilitarAbaAoVivo;
            setAbas(novasAbas);
            if(desabilitarAbaAoVivo || temPermissaoRBAC(user, PERMISSOES.CADASTRAR_CURSO)) {
                setAbaAtiva('1');
            } else {
                setAbaAtiva('0');
            }
        }
    }, [desabilitarAbaAoVivo]);

    return (
        <SttTabs abas={abas}
            canClose={true}
            className={classes.wrapper}
            handleChangeAbaAtiva={(aba) => {
                setAbaAtiva(aba);
                limparParametrosPesquisa();
            }}
            abaAtiva={abaAtiva}
            permanente={true}
        />

    );
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user,
        abaAtiva: state.webconferenciaReducer.abaAtiva,
        desabilitarAbaAoVivo: state.webconferenciaReducer.desabilitarAbaAoVivo
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setAbaAtiva: id => dispatch(setAbaAtivaAction(id)),
        limparParametrosPesquisa: () => dispatch(limparParametrosPesquisaAction()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PesquisaIndex);