import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { temPermissaoRBAC, temPermissaoVinculoEstadual } from '../../../../security/acl';
import HttpStatus from 'http-status-codes';
import {
    SttButton,
    SttContainer,
    SttCircularProgress,
    SttDivider,
    SttTranslateHook,
    SttTable,
    SttTableBody,
    SttTableCell,
    SttTableHead,
    SttTableRow,
    SttHidden,
    SttText,
    SttGrid,
    SttNotification,
    SttBox
} from '@stt-componentes/core';
import { v4 as uuidv4 } from 'uuid';
import { getHeaders } from '../../../../request';
import ModalAvaliacao from './avaliacao';
import ModalMaterial from './material';
import ModalRegistroAcesso from './acesso';
import { PERMISSOES, CONSTANTES_PERFIS } from '../../../../common/Constants';
import {
    setWebconferenciaAlteracao as setWebconferenciaAlteracaoAction
} from '../../../../reducers/actions/cadastro/webconferencia/index';
import {
    SttTeleeducacaoIcon
} from '@stt-componentes/icons';
import { temPerfil } from '../../../../security/acl';
import ListaPresenca from '../../../../componentes/interno/listaPresenca';
import ListaEquipeParticipante from '../../../../componentes/interno/listaEquipeParticipante';
import { mostrarCarregando as mostrarCarregandoAction } from '../../../../reducers/actions/carregando';
import {
    setParametrosAlerta as setParametrosAlertaAction,
    setOpen as setOpenAction
} from "../../../../reducers/actions/alerta";
import {
    setPesquisarEventosAndamento as setPesquisarEventosAndamentoAction
} from '../../../../reducers/actions/webconferencias';
import { tratarUrl } from '../../../../util';

const useStyles = makeStyles(theme => ({
    divTextItens: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    notificacao: {
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(1),
    },
    imagem: {
        width: '100%',
        height: '100%'
    },
    button: {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
        alignSelf: 'center',
    },
    buttonWrapper: {
        marginTop: theme.spacing(1),
        textAlign: 'start'
    },
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
    divBotaoAssistir: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
    },
    boxImagem: {
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center'
    }
}));

const useStylesHeading = makeStyles(theme => ({
    textoQuebraLinha: {
        wordBreak: 'break-all',
        whiteSpace: 'pre-line',
    },
}));

const ExibicaoHtml = ({ html }) => {
    const classesHeading = useStylesHeading();
    return (
        html?.trim() &&
        <>
            <SttText
                variant="body2"
                className={classesHeading.textoQuebraLinha}
                style={{ wordBreak: 'break-word' }}
                dangerouslySetInnerHTML={{ __html: html.trim() }}
            />
        </>
    )
}

const DetalhesWebconferencia = (props) => {
    const {
        id,
        user,
        setWebconferenciaAlteracao,
        contextoWebAoVivo = false,
        abaProximasWebs,
        setBuscar,
        itemBiblioteca,
        fecharModalDetalhes,
        mostrarCarregando,
        setParametrosAlerta,
        setOpen,
        setPesquisarEventosAndamento
    } = props;
    const { strings } = useContext(SttTranslateHook.I18nContext);

    const TELE_EDUCACAO_API_BASE_URL = global.gConfig.url_base_tele_educacao;
    const classes = useStyles();
    const [detalhes, setDetalhes] = useState(null);
    const [fotoWebconferencia, setFotoWebconferencia] = useState(null);
    const [recarregarWebconferencia, setRecarregarWebconferencia] = useState(false);
    const history = useHistory();

    // Avaliação da webconferência
    const [modalAvaliacao, setModalAvaliacao] = useState(false);
    const [webconferenciaAvaliacao, setWebconferenciaAvaliacao] = useState(null);
    const [avaliacaoEdicao, setAvaliacaoEdicao] = useState(false);

    // Material da webconferência
    const [modalMaterial, setModalMaterial] = useState(false);
    const [webconferenciaMaterial, setWebconferenciaMaterial] = useState(null);
    const [materialEdicao, setMaterialEdicao] = useState(null);

    // Registro de acesso
    const [visualizarHistoricoAcesso, setVisualizarHistoricoAcesso] = useState(false);
    const [podeRegistrarAcesso, setPodeRegistrarAcesso] = useState(false);

    useEffect(() => {
        carregarWebconferencia();
    }, [id]);

    useEffect(() => {
        if (recarregarWebconferencia) {
            carregarWebconferencia();
            setRecarregarWebconferencia(false);
        }
    }, [recarregarWebconferencia]);

    useEffect(() => {
        if (detalhes && detalhes.instituicao && user.habilitacao?.vinculo) {
            const ufs = user.habilitacao?.vinculo?.map(vinculo => vinculo.sigla_estado);
            setPodeRegistrarAcesso(ufs.includes(detalhes.instituicao.sigla_estado));
        }
    }, [user, detalhes]);

    const carregarWebconferencia = () => {
        if (id) {
            axios
                .get(`${TELE_EDUCACAO_API_BASE_URL}/webconferencia/${id}`, { headers: getHeaders() })
                .then((response) => {
                    const dados = response.data.data;
                    formatarDadosExibicao(dados);
                    setDetalhes(dados);
                })
                .catch(err => {
                    console.log(err);
                });
        }
    }

    const avaliarWebconferencia = () => {
        setWebconferenciaAvaliacao(id);
        setModalAvaliacao(true);
        setAvaliacaoEdicao(detalhes.avaliacaoFormatada);
    }

    const alterarWebconferencia = () => {
        setWebconferenciaAlteracao(id);
        history.replace("/cadastro/webconferencia");
        if (fecharModalDetalhes) {
            fecharModalDetalhes();
        }
    }

    const adicionarMaterialWebconferencia = () => {
        setWebconferenciaMaterial(id);
        setModalMaterial(true);
        setMaterialEdicao(detalhes.temMaterial ? detalhes.materiais[0] : null);
    }

    const formatarDadosExibicao = (dados) => {
        if (dados.enderecoSala) {
            dados.enderecoSala = tratarUrl(dados.enderecoSala);
        }

        // Foto da webconferência
        if (dados.arquivo) {
            let idArquivoCodificado = window.btoa(dados.arquivo.id);
            let url = `${TELE_EDUCACAO_API_BASE_URL}/material/${idArquivoCodificado}/${encodeURIComponent(dados.arquivo.nome)}`
            axios
                .get(url, { responseType: 'blob', headers: getHeaders() })
                .then((response) => {
                    if (response.data) {
                        let urlFoto = URL.createObjectURL(response.data);
                        setFotoWebconferencia(urlFoto);
                    }
                })
                .catch(err => { });
        }

        // Dados do material
        dados.temMaterial = false;
        if (dados.materiais?.length > 0) {
            const material = dados.materiais[0];
            dados.temMaterial = true;
            dados.temUrlExterna = material.url;
        }


        // Tratamento do horário da web ao vivo
        const formato = { hour: 'numeric', minute: 'numeric' };

        let data = new Date(dados.dataWebconferencia);
        const horarioInicio = data.toLocaleString('pt-BR', formato);

        data.setMinutes(data.getMinutes() + dados.cargaHoraria);
        const horarioTermino = data.toLocaleString('pt-BR', formato);

        dados.inicio = horarioInicio;
        dados.termino = horarioTermino;

        if (dados.instituicao) {
            dados.instituicaoFormatada = `${dados.instituicao.nome} (${dados.instituicao.nome_cidade}/${dados.instituicao.sigla_estado})`;
        }
    };

    const baixarMaterial = (url, temUrlExterna) => {
        if (temUrlExterna) {
            window.open(url);
            return;
        }
        axios.get(url, { responseType: 'blob', headers: getHeaders() })
            .then((response) => {
                var fileURL = URL.createObjectURL(response.data);
                window.open(fileURL);
            })
            .catch(err => { });
    }

    const assistirGravacao = () => {
        if (detalhes.temMaterial) {
            const material = detalhes.materiais[0];
            baixarMaterial(detalhes.temUrlExterna ? material.url : `${TELE_EDUCACAO_API_BASE_URL}/material/${window.btoa(material.id_arquivo)}/${encodeURIComponent(material.nome_arquivo)}`, detalhes.temUrlExterna);
        }
    }

    const assistirWebAoVivo = () => {
        setWebconferenciaAssistir(formatarWebconferencia(detalhes));
    }

    const formatarWebconferencia = (detalhes) => {
        if (detalhes?.instituicao?.sigla_estado && detalhes?.enderecoSala?.includes(global.gConfig.url_padrao_conferencia_web)) {
            if (user?.email && user?.nome) {
                var parametros = [
                    `user[email]=${user.email}`,
                    `user[name]=${user.nome}`,
                ];
                var novaURL = `${detalhes.enderecoSala}/join?` + parametros.join('&');
                console.log('Endereço da sala detalhes', novaURL);
                detalhes.enderecoSala = novaURL;
            }
        }
        return detalhes;
    }

    const [webconferenciaAssistir, setWebconferenciaAssistir] = useState();

    const handleRegistrarAcesso = () => {
        setVisualizarHistoricoAcesso(true);
    }

    const inativarWebconferencia = () => {
        const parametrosAlerta = {
            titulo: strings.sucesso,
            mensagem: strings.confirmacaoInativacao,
            tipo: 'success',
            open: true,
            opcoes: [
                {
                    title: strings.sim,
                    onClick: () => {
                        setOpen(false);
                        mostrarCarregando(true);
                        confirmarInativarWebconferencia();
                    }
                },
                {
                    title: strings.nao,
                    onClick: () => {
                        setOpen(false);
                    }
                }
            ],
            onClose: () => {
                setOpen(false);
            }
        };
        setParametrosAlerta(parametrosAlerta);
    }

    const confirmarInativarWebconferencia = () => {
        const dados = {
            id: detalhes.id_agenda
        };

        axios.post(`${TELE_EDUCACAO_API_BASE_URL}/inativar`, dados, { headers: getHeaders() })
            .then((response) => {
                if (response.status === HttpStatus.OK) {
                    const parametrosAlerta = {
                        titulo: strings.sucesso,
                        mensagem: strings.mensagemSucessoInativacao,
                        tipo: 'success',
                        open: true,
                        opcoes: [
                            {
                                title: strings.ok,
                                onClick: () => {
                                    setOpen(false);
                                    fecharModalDetalhes();
                                    setBuscar(true);
                                    setPesquisarEventosAndamento(true);
                                }
                            }
                        ],
                        onClose: () => {
                            setOpen(false);
                            fecharModalDetalhes();
                            setBuscar(true);
                            setPesquisarEventosAndamento(true);
                        }
                    };
                    setParametrosAlerta(parametrosAlerta);
                } else {
                    const parametrosAlerta = {
                        titulo: strings.erro,
                        mensagem: strings.erroGenericoInativar,
                        tipo: 'error',
                        open: true,
                        opcoes: [
                            {
                                title: strings.ok,
                                onClick: () => {
                                    setOpen(false);
                                }
                            }
                        ],
                        onClose: () => {
                            setOpen(false);
                        }
                    };
                    setParametrosAlerta(parametrosAlerta);
                }
            })
            .catch(err => {
                console.log(err);
                const { response } = err;
                let mensagem = strings.erroGenerico;
                if (response) {
                    const { data } = response;

                    mensagem = data.message;
                }

                const parametrosAlerta = {
                    titulo: strings.erro,
                    mensagem: mensagem,
                    tipo: 'error',
                    open: true,
                    opcoes: [
                        {
                            title: strings.ok,
                            onClick: () => {
                                setOpen(false);
                            }
                        }
                    ],
                    onClose: () => {
                        setOpen(false);
                    }
                };
                setParametrosAlerta(parametrosAlerta);
            })
            .finally(() => {
                mostrarCarregando(false);
            });
    }

    const podeModificarWebconferencia = () => {
        if (user?.habilitacao?.vinculo && detalhes?.instituicao?.id) {
            return user.habilitacao.vinculo.find(i => i.id === detalhes.instituicao.id);
        }
    }

    return (
        <SttContainer>
            {
                (!detalhes)
                    ?
                    <div className={classes.carregando}>
                        <SttCircularProgress color="primary" />
                    </div>
                    :
                    <>
                        <SttGrid container spacing={1}>
                            <SttGrid item xs={12} md={6} container alignItems="flex-start" justifyContent="center">
                                <SttBox className={fotoWebconferencia ? '' : classes.boxImagem}>
                                    {
                                        fotoWebconferencia ?
                                            <img src={fotoWebconferencia} className={classes.imagem} />
                                            : <SttTeleeducacaoIcon />
                                    }
                                </SttBox>
                            </SttGrid>
                            <SttGrid item xs={12} md={6}>
                                <SttTable stickyHeader>
                                    <SttTableHead>
                                        <SttTableRow>
                                            <SttHidden xsDown>
                                                {
                                                    contextoWebAoVivo &&
                                                    <>
                                                        <SttTableCell width="25%" align="center">{strings.inicio}</SttTableCell>
                                                        <SttTableCell width="25%" align="center">{strings.termino}</SttTableCell>
                                                    </>
                                                }
                                                {
                                                    abaProximasWebs &&
                                                    <>
                                                        <SttTableCell width="17%" align="center">{strings.data}</SttTableCell>
                                                        <SttTableCell width="16%" align="center">{strings.inicio}</SttTableCell>
                                                        <SttTableCell width="16%" align="center">{strings.termino}</SttTableCell>
                                                    </>
                                                }
                                                {
                                                    !abaProximasWebs && !contextoWebAoVivo &&
                                                    <SttTableCell width="50%" align="center">{strings.dataGravacao}</SttTableCell>
                                                }
                                                <SttTableCell width="50%" align="center">{strings.tipo}</SttTableCell>
                                            </SttHidden>
                                            <SttHidden smUp>
                                                <SttTableCell width="100%" align="center">{strings.detalhes}</SttTableCell>
                                            </SttHidden>
                                        </SttTableRow>
                                    </SttTableHead>
                                    <SttTableBody>
                                        <SttTableRow key={uuidv4()}>
                                            <SttHidden xsDown>
                                                {
                                                    contextoWebAoVivo &&
                                                    <>
                                                        <SttTableCell align="center">{detalhes.inicio ? detalhes.inicio : strings.naoInformado}</SttTableCell>
                                                        <SttTableCell align="center">{detalhes.termino ? detalhes.termino : strings.naoInformado}</SttTableCell>
                                                    </>
                                                }
                                                {
                                                    abaProximasWebs &&
                                                    <>
                                                        <SttTableCell align="center">
                                                            <Moment format="DD/MM/YYYY">
                                                                {detalhes.dataWebconferencia}
                                                            </Moment>
                                                        </SttTableCell>
                                                        <SttTableCell align="center">{detalhes.inicio ? detalhes.inicio : strings.naoInformado}</SttTableCell>
                                                        <SttTableCell align="center">{detalhes.termino ? detalhes.termino : strings.naoInformado}</SttTableCell>
                                                    </>
                                                }
                                                {
                                                    !abaProximasWebs && !contextoWebAoVivo &&
                                                    <SttTableCell align="center">
                                                        <Moment format="DD/MM/YYYY HH:mm:ss">
                                                            {detalhes.dataWebconferencia}
                                                        </Moment>
                                                    </SttTableCell>
                                                }
                                                <SttTableCell align="center">{detalhes.descricaoTipoAtividade ? detalhes.descricaoTipoAtividade : strings.naoInformado}</SttTableCell>
                                            </SttHidden>
                                            <SttHidden smUp>
                                                <SttTableCell width="100%" align="center">
                                                    <div className={classes.divTextItens}>
                                                        {
                                                            contextoWebAoVivo &&
                                                            <>
                                                                <SttText size="small">
                                                                    <b>{strings.inicio}:</b> {detalhes.inicio}
                                                                </SttText>
                                                                <SttText size="small">
                                                                    <b>{strings.termino}:</b> {detalhes.termino}
                                                                </SttText>
                                                            </>
                                                        }
                                                        {
                                                            abaProximasWebs &&
                                                            <>
                                                                <SttText size="small">
                                                                    <b>{strings.data}:</b> <Moment format="DD/MM/YYYY">
                                                                        {detalhes.dataWebconferencia}
                                                                    </Moment>
                                                                </SttText>
                                                                <SttText size="small">
                                                                    <b>{strings.inicio}:</b> {detalhes.inicio}
                                                                </SttText>
                                                                <SttText size="small">
                                                                    <b>{strings.termino}:</b> {detalhes.termino}
                                                                </SttText>
                                                            </>
                                                        }
                                                        {
                                                            !abaProximasWebs && !contextoWebAoVivo &&
                                                            <SttText size="small">
                                                                <b>{strings.dataGravacao}:</b> <br />
                                                                <Moment format="DD/MM/YYYY HH:mm:ss">
                                                                    {detalhes.dataWebconferencia}
                                                                </Moment>
                                                            </SttText>
                                                        }
                                                        <SttText size="small">
                                                            <b>{strings.tipo}:</b> <br /> {detalhes.descricaoTipoAtividade}
                                                        </SttText>
                                                    </div>
                                                </SttTableCell>
                                            </SttHidden>
                                        </SttTableRow>
                                    </SttTableBody>

                                    <SttTableHead>
                                        <SttTableRow>
                                            <SttHidden xsDown>
                                                <SttTableCell width="100%" colSpan={4} align="center">{strings.instituicaoResponsavel}</SttTableCell>
                                            </SttHidden>
                                            <SttHidden smUp>
                                                <SttTableCell width="100%" colSpan={4} align="center">{strings.instituicaoResponsavel}</SttTableCell>
                                            </SttHidden>
                                        </SttTableRow>
                                    </SttTableHead>
                                    <SttTableBody>
                                        <SttTableRow key={uuidv4()}>
                                            <SttHidden xsDown>
                                                <SttTableCell colSpan={4} align="center">{detalhes.instituicaoFormatada ? <ExibicaoHtml key={uuidv4()} html={detalhes.instituicaoFormatada} /> : strings.naoInformado}</SttTableCell>
                                            </SttHidden>
                                            <SttHidden smUp>
                                                <SttTableCell width="100%" colSpan={4}>
                                                    <div className={classes.divTextItens}>
                                                        <SttText size="small">
                                                            {detalhes.instituicaoFormatada ? <ExibicaoHtml key={uuidv4()} html={detalhes.instituicaoFormatada} /> : strings.naoInformado}
                                                        </SttText>
                                                    </div>
                                                </SttTableCell>
                                            </SttHidden>
                                        </SttTableRow>
                                    </SttTableBody>

                                    <SttTableHead>
                                        <SttTableRow>
                                            <SttHidden xsDown>
                                                <SttTableCell width="100%" colSpan={4} align="center">{strings.descricao}</SttTableCell>
                                            </SttHidden>
                                            <SttHidden smUp>
                                                <SttTableCell width="100%" colSpan={4} align="center">{strings.descricao}</SttTableCell>
                                            </SttHidden>
                                        </SttTableRow>
                                    </SttTableHead>
                                    <SttTableBody>
                                        <SttTableRow key={uuidv4()}>
                                            <SttHidden xsDown>
                                                <SttTableCell colSpan={4} align="center">{detalhes.descricaoAgenda ? <ExibicaoHtml key={uuidv4()} html={detalhes.descricaoAgenda} /> : strings.naoInformado}</SttTableCell>
                                            </SttHidden>
                                            <SttHidden smUp>
                                                <SttTableCell width="100%" colSpan={4}>
                                                    <div className={classes.divTextItens}>
                                                        <SttText size="small">
                                                            {detalhes.descricaoAgenda ? <ExibicaoHtml key={uuidv4()} html={detalhes.descricaoAgenda} /> : strings.naoInformado}
                                                        </SttText>
                                                    </div>
                                                </SttTableCell>
                                            </SttHidden>
                                        </SttTableRow>
                                    </SttTableBody>

                                    {
                                        detalhes.resumo && detalhes.resumo.trim() !== '' &&
                                        <>
                                            <SttTableHead>
                                                <SttTableRow>
                                                    <SttHidden xsDown>
                                                        <SttTableCell width="100%" colSpan={4} align="center">{strings.resumo}</SttTableCell>
                                                    </SttHidden>
                                                    <SttHidden smUp>
                                                        <SttTableCell width="100%" colSpan={4} align="center">{strings.resumo}</SttTableCell>
                                                    </SttHidden>
                                                </SttTableRow>
                                            </SttTableHead>
                                            <SttTableBody>
                                                <SttTableRow key={uuidv4()}>
                                                    <SttHidden xsDown>
                                                        <SttTableCell colSpan={4} align="center">{detalhes.resumo ? <ExibicaoHtml key={uuidv4()} html={detalhes.resumo} /> : strings.naoInformado}</SttTableCell>
                                                    </SttHidden>
                                                    <SttHidden smUp>
                                                        <SttTableCell width="100%" colSpan={4}>
                                                            <div className={classes.divTextItens}>
                                                                <SttText size="small">
                                                                    {detalhes.resumo ? <ExibicaoHtml key={uuidv4()} html={detalhes.resumo} /> : strings.naoInformado}
                                                                </SttText>
                                                            </div>
                                                        </SttTableCell>
                                                    </SttHidden>
                                                </SttTableRow>
                                            </SttTableBody>
                                        </>
                                    }

                                    <SttTableHead>
                                        <SttTableRow>
                                            <SttHidden xsDown>
                                                <SttTableCell width="100%" colSpan={4} align="center">{strings.informacoesPalestrantes}</SttTableCell>
                                            </SttHidden>
                                            <SttHidden smUp>
                                                <SttTableCell width="100%" colSpan={4} align="center">{strings.informacoesPalestrantes}</SttTableCell>
                                            </SttHidden>
                                        </SttTableRow>
                                    </SttTableHead>
                                    <SttTableBody>
                                        <SttTableRow key={uuidv4()}>
                                            <SttHidden xsDown>
                                                <SttTableCell colSpan={4} align="center">
                                                    <b>{strings.nome}:</b> {detalhes.palestrante ? detalhes.palestrante : strings.naoInformado}
                                                    <br />
                                                    <b>{strings.sobrePalestrante}:</b> {detalhes.descricaoPalestrante ? detalhes.descricaoPalestrante : strings.naoInformado}
                                                </SttTableCell>
                                            </SttHidden>
                                            <SttHidden smUp>
                                                <SttTableCell width="100%" colSpan={4} align="center">
                                                    <div className={classes.divTextItens}>
                                                        <SttText size="small">
                                                            <b>{strings.nome}:</b> {detalhes.palestrante ? detalhes.palestrante : strings.naoInformado}
                                                            <br />
                                                            <b>{strings.sobrePalestrante}:</b> {detalhes.descricaoPalestrante ? detalhes.descricaoPalestrante : strings.naoInformado}
                                                        </SttText>
                                                    </div>
                                                </SttTableCell>
                                            </SttHidden>
                                        </SttTableRow>
                                    </SttTableBody>
                                </SttTable>
                                {
                                    contextoWebAoVivo
                                        ?
                                        <div className={classes.divBotaoAssistir}>
                                            <SttButton
                                                align="center"
                                                type="button"
                                                variant="contained"
                                                color="primary"
                                                className={classes.button}
                                                onClick={assistirWebAoVivo}
                                            >
                                                {strings.cliqueParaAssistir}
                                            </SttButton>
                                        </div>
                                        :
                                        (
                                            detalhes.temMaterial
                                                ?
                                                <div className={classes.divBotaoAssistir}>
                                                    <SttButton
                                                        align="center"
                                                        type="button"
                                                        variant="contained"
                                                        color="primary"
                                                        className={classes.button}
                                                        onClick={assistirGravacao}
                                                    >
                                                        {strings.assistirGravacao}
                                                    </SttButton>
                                                </div>
                                                :
                                                (
                                                    !abaProximasWebs &&
                                                    <SttNotification severity="info" className={classes.notificacao}>
                                                        {strings.instrucaoSemGravacao}
                                                    </SttNotification>
                                                )
                                        )
                                }
                            </SttGrid>
                        </SttGrid>
                        <SttDivider />
                        {
                            !contextoWebAoVivo &&
                            <div className={classes.buttonWrapper}>
                                {
                                    temPermissaoRBAC(user, PERMISSOES.CADASTRAR_WEB) &&
                                    podeModificarWebconferencia() &&
                                    <>
                                        {
                                            temPermissaoVinculoEstadual(user, PERMISSOES.CADASTRAR_WEB, detalhes.instituicao ? detalhes.instituicao.sigla_estado : null) &&
                                            <SttButton
                                                nomarginleft={abaProximasWebs}
                                                type="button"
                                                variant="contained"
                                                color="primary"
                                                className={classes.button}
                                                onClick={alterarWebconferencia}
                                            >
                                                {strings.alterar}
                                            </SttButton>
                                        }
                                        {
                                            !abaProximasWebs &&
                                            temPermissaoVinculoEstadual(user, PERMISSOES.CADASTRAR_WEB, detalhes.instituicao ? detalhes.instituicao.sigla_estado : null) &&
                                            <SttButton
                                                type="button"
                                                variant="contained"
                                                color="primary"
                                                className={classes.button}
                                                onClick={adicionarMaterialWebconferencia}
                                            >
                                                {detalhes.temMaterial ? strings.alterarGravacao : strings.adicionarGravacao}
                                            </SttButton>
                                        }
                                        {
                                            !abaProximasWebs && !contextoWebAoVivo &&
                                            (
                                                temPerfil(user, CONSTANTES_PERFIS.ADMINISTRADOR) ||
                                                (temPerfil(user, CONSTANTES_PERFIS.ADMINISTRADOR_TELEEDUCACAO) && podeRegistrarAcesso) ||
                                                temPermissaoVinculoEstadual(user, PERMISSOES.CADASTRAR_WEB, detalhes.instituicao ? detalhes.instituicao.sigla_estado : null)
                                            ) &&
                                            <SttButton
                                                type="button"
                                                variant="contained"
                                                color="primary"
                                                className={classes.button}
                                                onClick={handleRegistrarAcesso}
                                            >
                                                {strings.registrosAcesso}
                                            </SttButton>
                                        }
                                    </>
                                }
                                {
                                    temPermissaoVinculoEstadual(user, PERMISSOES.INATIVAR_WEB, detalhes.instituicao ? detalhes.instituicao.sigla_estado : null) &&
                                    podeModificarWebconferencia() &&
                                    <SttButton
                                        type="button"
                                        variant="contained"
                                        color="primary"
                                        className={classes.button}
                                        onClick={inativarWebconferencia}
                                    >
                                        {strings.inativar}
                                    </SttButton>
                                }
                            </div>
                        }

                        {
                            modalAvaliacao &&
                            <ModalAvaliacao
                                setModalAvaliacao={setModalAvaliacao}
                                webconferenciaAvaliacao={webconferenciaAvaliacao}
                                setAvaliacaoEdicao={setAvaliacaoEdicao}
                                avaliacaoEdicao={avaliacaoEdicao}
                                setRecarregarWebconferencia={setRecarregarWebconferencia}
                            />
                        }

                        {
                            modalMaterial &&
                            <ModalMaterial
                                setModalMaterial={setModalMaterial}
                                idWebconferencia={webconferenciaMaterial}
                                setMaterialEdicao={setMaterialEdicao}
                                materialEdicao={materialEdicao}
                                setRecarregarWebconferencia={setRecarregarWebconferencia}
                                setBuscar={setBuscar}
                            />
                        }

                        {
                            !abaProximasWebs && !contextoWebAoVivo && visualizarHistoricoAcesso &&
                            <ModalRegistroAcesso
                                idWebconferencia={id}
                                dataWebconferencia={detalhes.dataWebconferencia}
                                setVisualizarHistoricoAcesso={setVisualizarHistoricoAcesso}
                                itemBiblioteca={itemBiblioteca}
                            />
                        }

                        {
                            webconferenciaAssistir &&
                            <>
                                {
                                    temPerfil(user, CONSTANTES_PERFIS.VISUALIZADOR_WEB)
                                        ?
                                        <ListaPresenca idWebconferencia={webconferenciaAssistir.id}
                                            urlWebconferencia={webconferenciaAssistir.enderecoSala}
                                            callback={() => setWebconferenciaAssistir(null)} />
                                        :
                                        <ListaEquipeParticipante
                                            idWebconferencia={webconferenciaAssistir.id}
                                            urlWebconferencia={webconferenciaAssistir.enderecoSala}
                                            resumoWebconferencia={webconferenciaAssistir.resumo}
                                            atualizarResumo={(resumoNovo) => {
                                                webconferenciaAssistir.resumo = resumoNovo;
                                                setWebconferenciaAssistir({ ...webconferenciaAssistir });
                                            }}
                                            estadoWebconferencia={webconferenciaAssistir.instituicao.sigla_estado}
                                            callback={() => setWebconferenciaAssistir(null)} />
                                }
                            </>

                        }
                    </>
            }
        </SttContainer>
    )
}

DetalhesWebconferencia.propTypes = {
    user: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        mostrarCarregando: (open, titulo) => dispatch(mostrarCarregandoAction(open, titulo)),
        setParametrosAlerta: (parametros) => dispatch(setParametrosAlertaAction(parametros)),
        setOpen: (open) => dispatch(setOpenAction(open)),
        setWebconferenciaAlteracao: id => dispatch(setWebconferenciaAlteracaoAction(id)),
        setPesquisarEventosAndamento: (pesquisar) => dispatch(setPesquisarEventosAndamentoAction(pesquisar))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DetalhesWebconferencia);