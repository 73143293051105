import TYPES from './types/webconferencia';

const INITIAL_STATE = {
    parametrosPesquisa: {
        titulo: '',
        palestrante: '',
        area_tematica: null,
        categoria: null,
        data_inicial: null,
        data_final: null,
        tipo_atividade: null,
        page: 0,
        count: 10,
        start: null,
        sort: null,
        direction: 'asc',
        tipo_pesquisa: null,
        webconferencias_registro_presenca: false
    },
    webconferenciaAlteracao: null,
    abaAtiva: null,
    desabilitarAbaAoVivo: false,
    pesquisarEventosAndamento: false,
    pesquisarEventosAgendados: false
};

function webconferenciarReducer(state, action) {
    if (!state) {
        state = INITIAL_STATE;
    }

    const { type, payload } = action;

    switch (type) {
        case TYPES.SET_PARAMETROS_PESQUISA:
            return {
                ...state,
                parametrosPesquisa: {
                    ...payload.parametros,
                    start: payload.parametros.page * payload.parametros.count
                }
            }

        case TYPES.LIMPAR_PARAMETROS_PESQUISA:
            return {
                ...state,
                parametrosPesquisa: INITIAL_STATE.parametrosPesquisa
            }

        case TYPES.SETAR_WEBCONFERENCIA_ALTERACAO:
            return {
                ...state,
                webconferenciaAlteracao: payload.idWebconferencia
            }

        case TYPES.SET_ABA_ATIVA:
            return {
                ...state,
                abaAtiva: payload.abaAtiva
            }

        case TYPES.SET_DESABILITAR_ABA_AO_VIVO:
            return {
                ...state,
                desabilitarAbaAoVivo: payload.desabilitarAbaAoVivo
            }

        case TYPES.SET_PESQUISAR_EVENTOS_ANDAMENTO:
            return {
                ...state,
                pesquisarEventosAndamento: payload.pesquisarEventosAndamento
            }

        case TYPES.SET_PESQUISAR_EVENTOS_AGENDADOS:
            return {
                ...state,
                pesquisarEventosAgendados: payload.pesquisarEventosAgendados
            }

        default:
            return state;
    }
}

export default webconferenciarReducer;